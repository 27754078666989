import { httpClient } from './httpClient';

export default {
  ekb: {
    update: (
      db,
      __name,
      __scope,
      __aliases,
      __tags,
      source,
      content,
      note,
      rid
    ) => {
      return httpClient.request({
        url: '/api/ekb/entry/update',
        method: 'post',
        params: {
          db
        },
        data: {
          __name: __name,
          __scope: __scope,
          __aliases: __aliases,
          __tags: __tags,
          source: source,
          content: content,
          note: note,
          '@rid': rid
        }
      })
    }
    ,
    create: (
      db,
      __name,
      __scope,
      __aliases,
      __tags,
      source,
      content,
      note
    ) => {
      return httpClient.request({
        url: '/api/ekb/entry/create',
        method: 'post',
        params: {
          db
        },
        data: {
          __name,
          __scope,
          __aliases,
          __tags,
          source,
          content,
          note
        }
      })
    },
    check: (
      db,
      _name,

    ) => {
      return httpClient.request({
        url: `/api/ekb/entry/property/searchByName?_name=${_name}`,
        method: 'get',
        params: {
          db,
          name: _name,
          projections: '*'
        }

      })
    },
    searchSimilar: (
      db,
      _name,

    ) => {
      return httpClient.request({
        url: `/api/ekb/entry/property/simSearchByName?_name=${_name}`,
        method: 'get',
        params: {
          db,
          name: _name,
          projections: '*'
        }

      })
    },
  }
}
