<template>
  <section class="mainPage full-size">
    <section class="content" :class="{ withSearchBar: hasSearchBar }">
      <a-tabs v-model:activeKey="state.activeKey" @change="onSearch">
        <a-tab-pane key="1" tab="搜阅">
          <section class="container" :class="{ withSearchBar: hasSearchBar }">
            <a-list
              item-layout="horizontal"
              :data-source="state.result"
              :locale="{ emptyText: '暂无相关结果数据' }"
            >
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-list-item-meta>
                    <template #title>
                      <!-- <span style="color: #333" @click="toDetail(item)">{{
                        item.title
                      }}</span> -->
                      <router-link
                        :to="
                          '/detail?id=' + item.projectId + '&path=' + item.path
                        "
                        >{{ item.title }}
                      </router-link>
                    </template>
                    <template #description>
                      <span v-html="item.description"></span>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
            </a-list>
          </section>
        </a-tab-pane>
        <!-- <a-tab-pane key="2" tab="图谱" force-render>
          <section class="container" :class="{ withSearchBar: hasSearchBar }"></section>
        </a-tab-pane> -->

        <a-tab-pane key="3" tab="百科">
          <section :class="{ withSearchBar: hasSearchBar }">
            <baike-items
              :data="state.baikeList"
              v-model:searchType="state.searchType"
              :search-value="state.searchValue"
              @searchSimilar="searchSimilar"
            ></baike-items>
          </section>
        </a-tab-pane>
        <a-tab-pane key="4" tab="反馈">
          <section
            class="container submitForm"
            :class="{ withSearchBar: hasSearchBar }"
          >
            <section>
              <p>主题：</p>
              <a-input
                v-model:value="state.questionData.theme"
                placeholder="请输入主题"
              />
            </section>
            <section>
              <p>内容：</p>

              <a-textarea
                v-model:value="state.questionData.content"
                placeholder="请输入内容"
                :rows="5"
                allow-clear
              />
            </section>
            <section>
              <p>联系方式：</p>
              <a-input
                v-model:value="state.questionData.contact"
                placeholder="请输入手机号码或者邮箱地址"
              />
            </section>
            <section>
              <a-button type="primary" style="width: 100%; margin: 12px 0"
                >提交</a-button
              >
            </section>
          </section>
        </a-tab-pane>
      </a-tabs>
    </section>

    <section v-if="hasSearchBar" class="search">
      <a-input-search
        v-model:value="state.searchValue"
        placeholder="输入要检索的内容"
        style="width: 100%"
        @search="onSearch"
        @keyup.enter="onSearch"
      />
    </section>
  </section>
</template>

<script>
import { computed, reactive, toRaw } from 'vue'
import commonApi from '@/api/common'
import kg from '../api/kg'
import { message } from 'ant-design-vue'
import BaikeItems from './baike/item.vue'
export default {
  name: 'Main',
  components: {
    BaikeItems,
  },
  setup() {
    const state = reactive({
      searchValue: '知识图谱',
      result: [],
      questionData: {
        theme: '',
        content: '',
        contact: '',
      },
      activeKey: '3',
      //是否添加
      ifAdd: true,
      //百科搜索结果
      baikeList: [],
      //搜索方式
      searchType: '1', //1,搜同名，2，搜相似
    })
    const onSearch = async () => {
      // state.activeKey = '1'
      if (!state.searchValue.trim()) {
        return
      }

      if (state.activeKey === '1') {
        commonApi.sooyue.search(state.searchValue.trim()).then((res) => {
          state.result = res.data.docSearchResultList.map((e) => e)
        })
      } else if (state.activeKey === '3') {
        state.searchType = '1'
        const response = await kg.ekb.check('ml', state.searchValue.trim())
        state.baikeList = []
        if (response.success) {
          response.data.forEach((element) => {
            state.baikeList.push({
              name: element.__name,
              scope: element.__scope,
              aliases: element.__aliases ? element.__aliases.join(',') : '',
              tags: element.__tags ? element.__tags.join(',') : '',
              source: element.source,
              content: element.content,
              note: element.note,
              rid: element['@rid'],
            })
          })
        }
      }
    }
    const searchSimilar = async () => {
      state.searchType = '2'
      const response = await kg.ekb.searchSimilar(
        'ml',
        state.searchValue.trim()
      )
      state.baikeList = []

      if (response.data.numFound > 0) {
        response.data.docs.forEach((element) => {
          state.baikeList.push({
            name: element.__name,
            scope: element.__scope,
            aliases: element.__aliases ? element.__aliases.join(',') : '',
            tags: element.__tags ? element.__tags.join(',') : '',
            source: element.source,
            content: element.content,
            note: element.note,
            rid: element['@rid'],
          })
        })
      }
    }

    const hasSearchBar = computed(() =>
      [1, 2, 3].includes(Number(state.activeKey))
    )

    return {
      state,
      onSearch,
      hasSearchBar,
      searchSimilar,
    }
  },
  mounted() {
    if (this.$route.query.search) {
      this.state.searchValue = this.$route.query.search
    }
    this.onSearch()
    window.addEventListener(
      'message',
      (event) => {
        if (
          event &&
          event.data &&
          String(event.data).indexOf('selectionText') > -1
        ) {
          this.state.searchValue = JSON.parse(event.data).selectionText
          this.onSearch()
        }
      },
      false
    )
  },
  methods: {
    toDetail: (item) => {
      this.$router.push({
        name: 'Detail',
        query: {
          id: item.workspaceId,
          path: item.path,
        },
      })
    },
  },
}
</script>

<style lang="less">
.mainPage {
  // display: flex;
  // flex-flow: column;
  overflow: hidden;
  //height: 100%;
  .content {
    //height: calc(100% - 50px);
    height: 100%;
    .ant-tabs {
      height: 100%;
      .ant-tabs-bar {
        margin-bottom: 0;
      }
    }
    .container {
      padding: 0 10px;
      //height: calc(100vh - 96px);
      height: 100%;
      overflow: auto;
    }
    .withSearchBar {
      height: calc(100vh - 96px) !important;
    }
    .submitForm {
      height: calc(100vh - 50px) !important;
      p {
        margin: 0;
        height: 32px;
        line-height: 32px;
      }
    }
  }
  .withSearchBar {
    height: calc(100% - 50px) !important;
    overflow: auto;
  }

  .search {
    height: 50px;
    border-top: solid 1px #f0f0f0;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
}
</style>
