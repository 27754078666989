<template>
  <section class="baikeItem">
    <a-collapse v-if="data.length > 0">
      <a-collapse-panel
        v-for="(dataItem, index) in data"
        :key="index"
        :header="dataItem.name"
      >
        <template #extra
          ><edit-outlined title="编辑" @click="handleEdit(dataItem)"
        /></template>
        <a-list item-layout="horizontal" :data-source="itemKeys">
          <template #renderItem="{ item }">
            <a-list-item v-show="dataItem[item.value]">
              <a-list-item-meta>
                <template #title>
                  <span>{{ item.desc }}</span>
                </template>
                <template #description>
                  <span v-if="item.value !== 'content'">{{
                    dataItem[item.value]
                  }}</span>
                  <markdown-render
                    v-else
                    class="markdown-render"
                    :markdown-data="dataItem[item.value]"
                  ></markdown-render>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </a-collapse-panel>
    </a-collapse>
    <a-empty :image="simpleImage" v-else>
      <template #description>
        <span> 暂无相关百科 </span>
      </template>
      <a-button type="primary" @click="toCreate">去创建</a-button>
      <a-button
        type="primary"
        style="margin-left: 10px"
        v-if="searchType === '1'"
        @click="searchSimilar"
        >搜相似</a-button
      >
    </a-empty>
    <p
      style="font-size: 12px; margin-top: 30px; text-align: center"
      v-if="data.length > 0"
    >
      没有想要的结果，<span
        style="color: #3884ff; cursor: pointer"
        @click="toCreate"
        >去创建</span
      >
    </p>
  </section>
</template>

<script>
import { Empty } from 'ant-design-vue'
import { EditOutlined } from '@ant-design/icons-vue'
import MarkdownRender from '@/components/MarkdownRender'

import { ref } from 'vue'

export default {
  name: 'Item',
  components: {
    EditOutlined,
    MarkdownRender,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    searchValue: {
      type: String,
      default: '',
    },

    searchType: {
      type: String,
      default: '1',
    },
  },
  setup() {
    const activeKey = ref([0])
    return {
      activeKey,
      itemKeys: [
        {
          value: 'scope',
          desc: '范围',
        },
        {
          value: 'aliases',
          desc: '同义词',
        },
        {
          value: 'tags',
          desc: '标签',
        },
        {
          value: 'source',
          desc: '来源',
        },
        {
          value: 'content',
          desc: '内容',
        },
        {
          value: 'note',
          desc: '备注',
        },
      ],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  methods: {
    searchSimilar() {
      this.$emit('searchSimilar')
    },
    toCreate() {
      this.$router.push({
        name: 'baikeEdit',
        query: {
          name: this.searchValue,
        },
      })
    },
    handleEdit(item) {
      this.$router.push({
        name: 'baikeEdit',
        query: {
          ...item,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.baikeItem {
  padding: 10px;
}
</style>